import "react-phone-number-input/style.css";

import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import React, { useEffect, useState } from "react";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, firebaseApp } from "../../firebase";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

import { FadeLoader } from "react-spinners";
import Modal from "react-modal";
import { connect } from "react-redux";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";

const firestore = getFirestore(firebaseApp);

const storage = getStorage();

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "50%",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const JobApplyForm = (props) => {
  //const { tenant } = props;
  let navigate = useNavigate();
  const { domain, jobData } = props;

  // const [isOpen, setIsOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [cover, setCover] = useState("");
  const [position, setPosition] = useState("");

  const [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#52BD94");

  const [existEmail, setExistEmail] = useState([]);
  //const [showRedirectPopUp, setShowRedirectPopUp] = useState(false)

  const [mobileNumberError, setMobileNumberLength] = useState(false);
  const [mobileValid, setMobileValid] = useState(false);
  const [fileurl, setFileUrl] = useState("");
  const [fileData, setFileData] = useState("");
  const [fileName, setFileName] = useState("");

  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  let [value, setValue] = useState(0);

  const [tenant, setTenant] = useState([]);
  useEffect(() => {
    getTenant(domain);
  }, []);

  const getTenant = async domain => {
    const colRef = collection(db, 'HiremeTenants')
    const todosQuery = query(colRef, where('portalDomain', '==', domain))
    const docSnap = await getDocs(todosQuery)

    let result = [];
    docSnap.docs.forEach((item) => {
      //console.log('itemmm:: ',item.id)
      result.push({ ...item.data(), id: item.id });
    });
    setTenant(result);
  };
  //console.log('tenant data arre:: ',tenant)

  const generateUniqueId = () => {
    var dt = new Date().getTime();

    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;

        dt = Math.floor(dt / 16);

        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );

    return uuid;
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setMobile("");
    setCover("");
  };

  console.log("jobData are:: ", jobData);

  async function saveAppliedJob(cadidateId, currentAttachements) {
    try {
      let applied_job_json = {
        candidate_id:
          cadidateId != null && cadidateId != "" && cadidateId != undefined
            ? cadidateId
            : existEmail[0].id,
        job_id:
          jobData && Object.keys(jobData).length > 0 && jobData.id
            ? jobData.id
            : "",
        job_title:
          jobData && Object.keys(jobData).length > 0 && jobData.job_title
            ? jobData.job_title
            : "",
        status: "New",
        applied_on: new Date().toISOString(),
        Attachment: currentAttachements,
      };

      const aplliedJobRef = collection(
        firestore,
        `HiremeApp/${tenant[0].id}/AppliedJobs`
      );
      let jobAppliedRes = await addDoc(aplliedJobRef, applied_job_json);
      console.log("jobAppliedRes areeee:: ", jobAppliedRes.id);
      return jobAppliedRes.id;
    } catch (error) {
      throw error;
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // console.log("singleJob:: ", singleJob);
      console.log("mobile length on submit:: ", mobile.length);

      if (existEmail && existEmail.length > 0 && mobileValid) {
        let candidateAttachment = [];
        if (existEmail[0].Attachment && existEmail[0].Attachment.length > 0) {
          candidateAttachment = existEmail[0].Attachment;
        } else {
          candidateAttachment.push(existEmail[0].Attachment);
        }

        console.log("candidateAttachment are::: ", candidateAttachment);

        setLoading(true);
        let tenantId = tenant && tenant.length > 0 ? tenant[0].id : "";
        console.log("tenantId are:: ", tenantId);
        let folderName = `HiremeApp/${tenantId}/Candiates`;
        let name;
        if (fileData !== "" && fileData !== null && fileData !== undefined) {
          console.log("filedataa re:: ", fileData);
          name = fileData.name;
          let value = generateUniqueId();
          let newName = "";
          newName = newName.concat(value, "_", name);

          // create a storage ref
          const fileRef = ref(storage, `${folderName}/${newName}`);

          // upload file
          const task = uploadBytesResumable(fileRef, fileData);

          // update progress bar
          task.on(
            "state_change",
            function progress(snapshot) {
              setValue((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            },
            function error(err) {
              console.log("errrrrrr areee::: ", err);
              setLoading(false);
              //alert(error)
            },
            async function complete(data) {
              const downloadURL = await getDownloadURL(
                ref(storage, `${folderName}/${newName}`)
              );
              setFileUrl(downloadURL);

              console.log("downloadURL:: ", downloadURL);
              candidateAttachment.push({
                cover_letter: cover,
                resume: downloadURL,
                applied_on: new Date().toISOString(),
              });
              let json = {
                Attachment: candidateAttachment,
                ProfessionalDetails: {
                  current_job_title:
                    jobData && Object.keys(jobData).length > 0 && jobData.id
                      ? jobData.id
                      : "",
                  job_name:
                    jobData &&
                    Object.keys(jobData).length > 0 &&
                    jobData.job_title
                      ? jobData.job_title
                      : "",
                },
              };
              console.log("jsonnn are:: ", json);
              const dbInstance = doc(
                db,
                `HiremeApp/${tenant[0].id}/Candidates`,
                existEmail[0].id
              );

              await updateDoc(dbInstance, json);
              let currentAttachements = {
                cover_letter: cover,
                resume: downloadURL,
                applied_on: new Date().toISOString(),
              };
              await saveAppliedJob(null, currentAttachements);
              setLoading(false);
              resetForm();
              setFileName("");
              navigate(`/${domain}/thankyou`);
            }
          );
        }
      } else {
        if (mobileValid) {
          let candidateAttachment = [];

          setLoading(true);
          let tenantId = tenant && tenant.length > 0 ? tenant[0].id : "";
          console.log("tenantId are:: ", tenantId);
          let folderName = `HiremeApp/${tenantId}/Candiates`;
          let name;
          if (fileData !== "" && fileData !== null && fileData !== undefined) {
            console.log("filedataa re:: ", fileData);
            name = fileData.name;
            let value = generateUniqueId();
            let newName = "";
            newName = newName.concat(value, "_", name);

            // create a storage ref
            const fileRef = ref(storage, `${folderName}/${newName}`);

            // upload file
            const task = uploadBytesResumable(fileRef, fileData);

            // update progress bar
            task.on(
              "state_change",
              function progress(snapshot) {
                setValue(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
              },
              function error(err) {
                console.log("errrrrrrrr:: ", err);
                setLoading(false);
                //alert(error)
              },
              async function complete(data) {
                const downloadURL = await getDownloadURL(
                  ref(storage, `${folderName}/${newName}`)
                );
                setFileUrl(downloadURL);
                candidateAttachment.push({
                  cover_letter: cover,
                  resume: downloadURL,
                  applied_on: new Date().toISOString(),
                });
                console.log("downloadURL:: ", downloadURL);
                let json = {
                  BasicInfo: {
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    mobile: mobile,
                  },
                  Attachment: candidateAttachment,
                  OtherInfo: {
                    created_at: new Date().toISOString(),
                    modified_at: new Date().toISOString(),
                    created_by: "JobPortal",
                    source: "JobPortal",
                    candidate_status: "New",
                  },
                  ProfessionalDetails: {
                    current_job_title:
                      jobData && Object.keys(jobData).length > 0 && jobData.id
                        ? jobData.id
                        : "",
                    job_name:
                      jobData &&
                      Object.keys(jobData).length > 0 &&
                      jobData.job_title
                        ? jobData.job_title
                        : "",
                  },
                  dropped: 0,
                };
                console.log("jsonnn are:: ", json);

                const dbInstance = collection(
                  firestore,
                  `HiremeApp/${tenant[0].id}/Candidates`
                );
                let candidateDocId = await addDoc(dbInstance, json);
                console.log("candidateDocId idddd:: ", candidateDocId.id);
                let currentAttachements = {
                  cover_letter: cover,
                  resume: downloadURL,
                  applied_on: new Date().toISOString(),
                };
                await saveAppliedJob(candidateDocId.id, currentAttachements);

                auth.tenantId = tenant && tenant.length > 0 && tenant[0].id;

                let chars =
                  "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
                let passwordLength = 8;
                let password = "";

                for (var i = 0; i <= passwordLength; i++) {
                  var randomNumber = Math.floor(Math.random() * chars.length);
                  password += chars.substring(randomNumber, randomNumber + 1);
                }
                console.log("password are:: ", password);

                let userCredential = await createUserWithEmailAndPassword(
                  auth,
                  email,
                  password
                );
                console.log("userCredential are::: ", userCredential);

                console.log("user id are::: ", userCredential.user.uid);

                let userJson = {
                  OtherInfo: {
                    created_at: new Date().toISOString(),
                    modified_at: new Date().toISOString(),
                    source: "JobPortal",
                  },
                  candidate_id: candidateDocId.id,
                  domain: tenant && tenant.length > 0 ? tenant[0].domain : "",
                  first_name: firstName,
                  last_name: lastName,
                  email: email,
                  mobile: mobile,
                  role: "Candidate",
                  tenant_id: tenant && tenant.length > 0 ? tenant[0].id : "",
                  status: true,
                  is_invited: true,
                  user_id: userCredential.user.uid,
                };

                // Add a new document with a generated id
                const UserRef = doc(
                  db,
                  `HiremeApp/${tenant[0].id}/Users`,
                  `${userCredential.user.uid}`
                );
                //const frankDocRef = doc(db, "users", "frank");
                await setDoc(UserRef, userJson);

                // const UserDbInstance = collection(firestore,`HiremeApp/${tenant[0].id}/Users`)
                // let userDocId = await addDoc(UserDbInstance, userJson);

                //console.log('userDocId idddd:: ',userDocId.id)

                const CandidateDbInstance = doc(
                  db,
                  `HiremeApp/${tenant[0].id}/Candidates`,
                  candidateDocId.id
                );
                await updateDoc(CandidateDbInstance, { invite: true });

                setLoading(false);
                resetForm();
                setFileName("");
                navigate(`/${domain}/thankyou`);
              }
            );
          }
        } else {
          setMobileNumberLength(true);
        }
      }
    } catch (err) {
      console.log("errrorrr:: ", err);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "first_name") {
      setFirstName(value);
    }
    if (name === "last_name") {
      setLastName(value);
    }
    if (name === "email") {
      setEmail(value);
    }
    // if (name === 'mobile') {
    //   console.log('mobile number length:: ', value.length)

    //   var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/

    //   let res = re.test(value)
    //   console.log('ress are:: ', res)

    //   if (res) {
    //     setMobileNumberLength(false)
    //     setMobileValid(true)
    //   } else {
    //     setMobileNumberLength(true)
    //     setMobileValid(false)
    //   }
    //   setMobile(value)
    // }
    if (name === "cover_letter") {
      setCover(value);
    }
    if (name === "position") {
      setPosition(value);
    }
  };

  const handleChangePhoneInput = (value) => {
    console.log("event234", value);

    if (value) {
      const isValid = isValidPhoneNumber(value);

      console.log("isValid", isValid);

      const isPossible = isPossiblePhoneNumber(value);

      console.log("isPossible", isPossible);

      const isFormatNumber = formatPhoneNumber(value);

      console.log("isFormatNumber", isFormatNumber);

      const isFormatPhoneNumberIntl = formatPhoneNumberIntl(value);

      console.log("isFormatPhoneNumberIntl", isFormatPhoneNumberIntl);

      if (isValid === true && isPossible === true) {
        setMobileNumberLength(false);

        setMobileValid(true);
      } else {
        setMobileNumberLength(true);

        setMobileValid(false);
      }

      setMobile(value);
    } else {
      setMobileNumberLength(false);

      setMobile("");

      setMobileValid(false);
    }
  };

  const handleFileChange = async (e) => {
    try {
      e.preventDefault();
      setFileUrl("");
      let reader = new FileReader();
      let file = e.target.files[0];
      //console.log("fileee:: ", file);
      setFileName(file.name);
      reader.onloadend = () => {
        // setImageUrl(reader.result)
      };
      reader.readAsDataURL(file);
      setFileData(file);
    } catch (error) {}
  };

  const handleEmailCheck = async (e) => {
    console.log("emailll are: ", email);
    let constraints = [];
    constraints.push(where("BasicInfo.email", "==", email));

    const userRef = collection(db, `HiremeApp/${tenant[0].id}/Candidates`);

    let userQuery = query(userRef, ...constraints);

    //todosQuery = query(todosQuery,  where('job_title', '===', jobSearch.title))
    // console.log('todosQuery are:: ',todosQuery)
    const docSnap = await getDocs(userQuery);

    let result = [];
    docSnap.docs.forEach((item) => {
      result.push({ ...item.data(), id: item.id });
    });

    console.log("result are:: ", result);
    if (result && result.length > 0) {
      setIsOpen(true);
    }

    setExistEmail(result);
  };

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }
  console.log("tenant are in apply page:: ", tenant);
  return (
    <>
      {loading ? (
        <div className="loader-div">
          <FadeLoader
            color={color}
            loading={loading}
            cssOverride={override}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : null}

      {tenant && tenant.length > 0 ? (
        <div id="redirect-modal">
          <Modal
            isOpen={modalIsOpen}
            //onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            onHide={closeModal}
          >
            {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
            <div
              style={{
                display: "flex",
                marginLeft: "650px",
                marginTop: "-25px",
                marginBottom: "5px",
              }}
            >
              <span
                onClick={closeModal}
                style={{
                  cursor: "pointer",
                  padding: "5px",
                  fontSize: "27px",
                  fontWeight: "600",
                }}
              >
                x
              </span>
            </div>

            <div style={{ marginBottom: "10px",padding:'5px' }}>
              Your account already exists in our database. Please{" "}
              <a
                target="__blank"
                href={`${process.env.REACT_APP_HIREME_URL}signin?companyId=${tenant[0].companyId}&companyName=${tenant[0].companyName}`}
              >
                Click here
              </a>{" "}
              to login to your Candidate Account and apply!
            </div>
            {/* <div>
              {" "}
              <a
                target="__blank"
                href={`${process.env.REACT_APP_HIREME_URL}signin?companyId=${tenant[0].companyId}&companyName=${tenant[0].companyName}`}
              >
                Redirect to APP
              </a>
            </div> */}
            {/* <form>
          <input />
          <button>tab navigation</button>
          <button>stays</button>
          <button>inside</button>
          <button>the modal</button>
        </form> */}
          </Modal>
        </div>
      ) : null}
      <section className="container">
        <div className="form-container">
          <div className="heading">
            <h4 className="typo-h4">Apply for this Job</h4>
            <h6 className="typo-h6">
              Please complete the form below to apply for the position
            </h6>
          </div>

          <div className="form-inner">
            <form onSubmit={handleSubmit} className="signup">
              <div className="formfield">
                <div className="leftfield">
                  <div className="field">
                    <label className="typo-h6">
                      First Name <span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      placeholder="First Name"
                      value={firstName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="field">
                    <label className="typo-h6">
                      Email <span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      name="email"
                      placeholder="Email"
                      value={email}
                      onChange={handleChange}
                      onBlur={handleEmailCheck}
                      required
                    />
                  </div>
                </div>
                <div className="rightfield">
                  <div className="field">
                    <label className="typo-h6">
                      Last Name <span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      name="last_name"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="field">
                    <label className="typo-h6">
                      Phone <span className="red">*</span>
                    </label>
                    {/* <input
                      type="text"
                      name="mobile"
                      placeholder="Phone"
                      value={mobile}
                      onChange={handleChange}
                      required
                    />
                    {mobileNumberError ? <span>Please Enter 10 Digit Mobile Number </span> : null} */}

                    <PhoneInput
                      className="phoneInput"
                      placeholder="Enter phone number"
                      defaultCountry="IN"
                      value={mobile}
                      onChange={(value) => handleChangePhoneInput(value)}
                      required
                    />

                    {mobileNumberError ? (
                      <span style={{ color: "red" }}>
                        Please select correct Country and Enter Correct Mobile
                        Number{" "}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              {/* <div className='formfield'>
                <div className='field'>
                  <label className='typo-h6'>Cover Letter</label>
                  <textarea
                    id='w3review'
                    name='cover_letter'
                    rows={4}
                    cols={150}
                    value={cover}
                    onChange={handleChange}
                    style={{ width: '94%' }}
                  />
                </div>
              </div> */}
              <div className="formfield">
                <div className="rightfield">
                  <div className="field">
                    <label className="typo-h6">
                      Upload Resume <span className="red">*</span>
                    </label>
                    <div className="uploadfilecss">
                      <img src="/assets/images/Upload icon.png" />

                      {/* <p className="typo-h6" />Drag &amp; drop files or  */}
                      {/* <input id="upload" type="file" /> */}

                      <span id="upload_link">
                        {" "}
                        <input
                          type="file"
                          name="awsm_file"
                          onChange={handleFileChange}
                          accept=".pdf, .doc, .docx"
                          required
                        />
                        Browser
                      </span>
                      {/* <a href="true" id="upload_link">Browser</a></p> */}
                      <p className="typo-p-14">Supported formates: Word, PDF</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="frombtn">
                <input
                  type="submit"
                  name="form_sub"
                  disabled={loading ? true : false}
                  className="button button-secondary"
                  value="Apply
                  Now"
                />
              </div>
            </form>
            {/* Start Signup Form */}
          </div>
        </div>
      </section>
    </>
  );
};

//export default JobApplyForm

const mapStateToProps = (state) => ({
  loading: state.jobReducer.loading,
  jobData: state.jobReducer.job,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(JobApplyForm);
