import React,{useEffect, useMemo, useState} from 'react';
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, limit, onSnapshot, query, where } from 'firebase/firestore';

import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import { db } from '../../firebase';
import { setJob } from '../../redux/actions/job.action';

const InnerHeader = (props) => {
  const { tenant, setJob, domain } = props
 // console.log('location path:: ',window.location)
  let pathName = window.location.pathname
  console.log('location path:: ',pathName)
  let splitText = pathName.split("/");
  console.log('splitText are:: ',splitText)
  console.log('tenant are:::',tenant)
  const [getJobFlag, setGetJobFlag] = useState(true)
  const [job,setJobs] = useState([])
    //const { job } = props
    //console.log('props are:: ',[props,window.location]);//12345

    let searchUrl = window.location.pathname
    let uid = searchUrl.split("/")
    console.log('uid are::',uid)

    const fetchJob = async (tenant, uid) => {
      //console.log('uid are:: ',uid)
      //const colRef = collection(db, `HiremeApp/${tenantId}/JobOpenings`);
      const docRef = doc(db, `HiremeApp/${tenant.id}/JobOpenings`, uid);
      const todosQuery = query(docRef);
      const docSnap = await getDoc(todosQuery);
  
  
      //let result = {};
  
      if (docSnap.exists()) {
        setJobs({ ...docSnap.data(), id: docSnap.id });
      }
  
      // onSnapshot(doc(db, "HiremeApp/WebutersTechnologies-xwvy0/JobOpenings", uid), (doc) => {
      //     console.log("Current data: ", doc.data());
      //     setJobs({ ...docSnap.data(), id: docSnap.id });
      // });
      setJob({ ...docSnap.data(), id: docSnap.id })
      //setJobs(result)
    }

     console.log('job are::',job)

    if(uid && uid.length > 3 && tenant && tenant.length > 0 && getJobFlag){
      fetchJob(tenant[0], uid[3]);
      setGetJobFlag(false)
    }
    
     return (
       <>
        <div id="header" className="jobdes-banner">
        <div className="container">
          <div className="jobhero-holesec">
            {uid && uid.length > 0 && uid[2] == 'jobApply' ? 
             <>
              <div className="jobhero-text">
                <div className="jobhero-texttop">
                  <h3 className="typo-h3">
                    Upload Your Resume
                  </h3>
                </div>
              </div>
             </>
            :  
            <div className="jobhero-text">
              <div className="jobhero-texttop">
              {/* <h3 className="typo-h3">{job && job.job_title} <span className="typo-h6"> (Job ID - {job && job.job_id})</span>
                </h3> */}
                  <h3 className="typo-h3">{job && job.job_title} </h3>
                  <span className="typo-h6"> (Job ID - {job && job.job_id})</span> 
               
                <div className="jobhero-textbages">
                  <p className="typo-h6">{job && job.job_type}</p>
                  <p className="typo-h6"><img src="/assets/images/map-pin.png" />{job.location ? job.location: 'NA'}</p>
                  <p className="typo-h6"><img src="/assets/images/briefcase.png" />Exp: {job && job.experience_needed}</p>
                  <p className="typo-h6"><img src="/assets/images/clock.png" />{job && job.job_opening_date}</p>
                </div>
              </div>
                  {
                  splitText && splitText.length > 0 && splitText[4] == "jobApply" 
                    ? null
                    :
                    <div className="jobhero-btn">
                    <Link to={`/${domain}/job/${job.id}/jobApply`} className="button button-secondary">Apply Now</Link>
                  </div>
                  }
                  
            </div>
            }
            
          </div>
        </div>
      </div>
      </>
    )
}

//export default InnerHeader

const mapStateToProps = (state) => ({
  loading: state.jobReducer.loading,
  jobData: state.jobReducer.job
})

const mapDispatchToProps = {
  setJob,
}

export default connect(mapStateToProps, mapDispatchToProps)(InnerHeader)