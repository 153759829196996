import { JobApply, JobDetails, JobList } from './pages/jobs'
import { Navigate, Route, Routes, createSearchParams, useNavigate } from "react-router-dom"
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, limit, query, where } from 'firebase/firestore';
//import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createContext, useContext, useEffect, useState } from "react"

//import { Footer } from './components/footer'
import HomePage  from './pages/HomePage'
import { HostContext } from "./App"
//import { NavBar } from './components/navbar'
import { NotFound } from "./pages/not-found"
import { ThankYou } from './pages/thankyou'
import { db } from './firebase';

const AppRouter = (props) => {
    
    const { domain } = props
    // console.log('in app route fileee::: ',domain)


    const [tenant,setTenant]=useState([])

    useEffect(() => {
        console.log('useEfect areee:: ',domain)
        getTenant(domain)
      }, [])

    const getTenant = async (domain)=>{
       //console.log('domain are@@@@@@@@@@@@@::: ',domain)
        const colRef = collection(db, "HiremeTenants");
        const todosQuery = query(colRef, where('portalDomain', '==', domain));
        const docSnap = await getDocs(todosQuery);

        let result = [];
        docSnap.docs.forEach(item=>{
            //console.log('itemmm:: ',item.id)
            result.push({ ...item.data(), id: item.id });
            
        })
        console.log('result are:: ',result)
        setTenant(result)
    }

    console.log('tenant are:: ',tenant)
  return (
    <>
        
       
        <HostContext.Consumer>
            {(domain) => {
                return (
                    <>
                    {/* <NavBar domain={domain} tenant={tenant}/>  */}
                    <Routes>
                        {/* <Route path="/jobs" element={<JobList domain={domain} tenant={tenant}/>} />
                        <Route path="/job/:jobId" element={<JobDetails domain={domain}/> } /> */}
                        {/* <Route path="/job/:jobId/jobApply" element={<JobApply domain={domain}/> } /> */}
                        {/* <Route path="/jobApply" element={<JobApply domain={domain}/> } /> */}
                        {/* <Route path="/thankyou" element={<ThankYou domain={domain}/> } /> */}

                        <Route path="/:tenant" element={<JobList domain={domain} tenant={tenant} isTenant={tenant && tenant.length > 0 ? true : false}/>} />
                        <Route path="/:tenant/job/:jobId" element={<JobDetails domain={domain}/> } />
                        <Route path="/:tenant/job/:jobId/jobApply" element={<JobApply domain={domain}/> } />
                        <Route path="/:tenant/jobApply" element={<JobApply domain={domain} /> } />
                        <Route path="/:tenant/thankyou" element={<ThankYou domain={domain} tenant={tenant}/> } />

                        {/* <Route path='/' element={ <Navigate to="/jobs" /> }/> */}
                        <Route path='/' element={ <HomePage /> }/>
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                    {/* <Footer domain={domain} tenant={tenant}/> */}
                    </>
                )
            }}
       
        </HostContext.Consumer>
       
     </>
  );
}

export  {AppRouter};
