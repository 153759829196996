export const GET_JOB_REQUEST = 'GET_JOB_REQUEST'
export const GET_JOB_SUCCESS = 'GET_JOB_SUCCESS'
export const GET_JOB_ERROR = 'GET_JOB_ERROR'

export const SET_JOB_REQUEST = 'SET_JOB_REQUEST'
export const SET_JOB_SUCCESS = 'SET_JOB_SUCCESS'
export const SET_JOB_ERROR = 'SET_JOB_ERROR'

export const ONSEARCH_JOB_REQUEST = 'ONSEARCH_JOB_REQUEST'
export const ONSEARCH_JOB_SUCCESS = 'ONSEARCH_JOB_SUCCESS'
export const ONSEARCH_JOB_ERROR = 'ONSEARCH_JOB_ERROR'

export const CURRENT_PAGE_JOB_REQUEST = 'CURRENT_PAGE_JOB_REQUEST'
export const CURRENT_PAGE_JOB_SUCCESS = 'CURRENT_PAGE_JOB_SUCCESS'
export const CURRENT_PAGE_JOB_ERROR = 'CURRENT_PAGE_JOB_ERROR'


export const GET_JOBLIST_REQUEST = 'GET_JOBLIST_REQUEST'
export const GET_JOBLIST_SUCCESS = 'GET_JOBLIST_SUCCESS'
export const GET_JOBLIST_ERROR = 'GET_JOBLIST_ERROR'