import React, { createContext, useState } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy
} from "firebase/firestore";

import { Footer } from '../../components/footer'
import { HomeContainer } from "../../components/container";
import { MainHeader } from "../../components/header";
import { NavBar } from '../../components/navbar'
import _ from "lodash";
import { connect } from "react-redux";
import { db } from "../../firebase";
import { getJobList } from "../../redux/actions/job.action";

export const MyContext = createContext();

const JobList = (props) => {
  
  const { tenant, domain, isTenant } = props;
  const [jobs, setJobs] = useState([]);
  const [getJobFlag, setGetJobFlag] = useState(true);


  const { getJobList, jobList } = props;

  const [value, setValue] = useState(1);
  const [searchValueFlag, setSearchValueFlag] = useState(false);
  const [jobSearch, setJobSearch] = useState({});
  const incrementValue = () => {
    setValue((oldState) => {
      return oldState + 1;
    });
  };

  const onChangeValue = (data) => {
    setJobSearch((oldState) => ({
      ...data,
    }));
    fetchJob(tenant[0].id, data, true);
  };

  const fetchJobs = async (tenantId, jobSearch, initialLoad) => {
    let constraints = [];
    constraints.push(where("status", "==", "Published"));

    if (
      jobSearch.title !== "" &&
      jobSearch.title !== null &&
      jobSearch.title !== undefined
    ) {
      constraints.push(where("job_title", "==", jobSearch.title));
    }

    if (
      jobSearch.location !== "" &&
      jobSearch.location !== null &&
      jobSearch.location !== undefined
    ) {
      constraints.push(where("location", "==", jobSearch.location));
    }

    if (
      jobSearch.job_type !== "" &&
      jobSearch.job_type !== null &&
      jobSearch.job_type !== undefined
    ) {
      constraints.push(where("job_type", "==", jobSearch.job_type));
    }

    const colRef = collection(db, `HiremeApp/${tenantId}/JobOpenings`);

    let jobsQuery = query(colRef, ...constraints,orderBy("OtherInfo.created_at","desc"));
    console.log("jobsQuery::",jobsQuery)
    const docSnap = await getDocs(jobsQuery);
    console.log("docSnap::",docSnap)
    let result = [];
    docSnap.docs.forEach((item) => {
      result.push({ ...item.data(), id: item.id });
    });
    setJobs(result);
    setGetJobFlag(false);
    getJobList(result);
  };
  if (tenant && tenant.length > 0 && getJobFlag) {
    console.log('@@@@@@@@@::::')
    fetchJobs(tenant[0].id, jobSearch, false);
    setGetJobFlag(false);
  }


  const fetchJob = (tenantId, jobSearch, initialLoad) => {
    const newArray = [];

    _.filter(jobList, function (o) {
      if (
        _.startsWith(
          _.trim(_.lowerCase(o.job_title)),
          _.lowerCase(jobSearch.title)
        )
      )
        if (
          _.startsWith(
            _.trim(_.lowerCase(o.location)),
            _.lowerCase(jobSearch.location)
          )
        )
          if (
            _.startsWith(
              _.trim(_.lowerCase(o.job_type)),
              _.lowerCase(jobSearch.job_type)
            )
          ) {
            newArray.push(o);
          }
    });

    setJobs(newArray);
    setGetJobFlag(false);
  };

  return (
    <>
      <NavBar domain={domain} tenant={tenant}/>            
      <MyContext.Provider
        value={{
          tenant,
          domain,
          jobs,
          value,
          jobSearch,
          onChangeValue,
          incrementValue,
          searchValueFlag,
          setSearchValueFlag,
          
        }}
      >
        <MainHeader />
        <HomeContainer tenant={tenant} domain={domain} isTenant={isTenant} />
      </MyContext.Provider>

      <Footer domain={domain} tenant={tenant}/>
    </>
  );
};

// export default JobList;
const mapStateToProps = (state) => ({
  jobList: state.jobReducer.jobList,
});

const mapDispatchToProps = {
  getJobList,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobList);
