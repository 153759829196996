import {
  CircleLoader,
  ClipLoader,
  DotLoader,
  FadeLoader,
  SyncLoader
} from 'react-spinners'
import { Link, useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  query,
  where
} from 'firebase/firestore'

import { db } from '../../firebase'

const override = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red'
}

const InnerJobDetailContainer = props => {
  const { job, domain } = props
  //console.log('job areee::',job)

  const [loading, setLoading] = useState(true)
  const [loadingFlag, setLoadingFlag] = useState(true)
  let [color, setColor] = useState('#52BD94')

  if (job && Object.keys(job).length > 0 && loadingFlag) {
    setLoadingFlag(false)
    setLoading(false)
  }
  return (
    <>
      {loading ? (
        <FadeLoader
          color={color}
          loading={loading}
          cssOverride={override}
          size={150}
          aria-label='Loading Spinner'
          data-testid='loader'
        />
      ) : null}
      <section className='container'>
        <div className='jobdescription'>
          <div className='paragraph'>
            <h4 className='typo-h4'>Job Description</h4>
            {/* <p className="typo-p-14">{job && job.job_description }</p> */}
            <div
              dangerouslySetInnerHTML={{
                __html: job && job.job_description
              }}
            />
          </div>
          {/* <div className='paragraph' style={{ marginTop: '3rem' }}>
            <h4 className='typo-h4' style={{ marginTop: '75px' }}>
              Responsibilities
            </h4>
            <div
              dangerouslySetInnerHTML={{
                __html: job && job.requirement
              }}
            />
          </div> */}
        </div>
        <div className='jobdescription'>
          <div className='breifdec'>
            <h4 className='typo-h4'>Benefits and Perks</h4>
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: job && job.benifit
                }}
              />
            </div>
          </div>
        </div>
        <div className='jobdec-button'>
          <Link
            to={`/${domain}/job/${job.id}/jobApply`}
            className='button button-secondary'
          >
            Apply Now
          </Link>
        </div>
      </section>
    </>
  )
}

export { InnerJobDetailContainer } 
