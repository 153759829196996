// const firebaseConfig = {
//     apiKey: "AIzaSyC9LAu0qv2gAHMPiIdPLqUA9cqQBVydz6c",
//     authDomain: "recruitment-tracking-app-dev.firebaseapp.com",
//     projectId: "recruitment-tracking-app-dev",
//     storageBucket: "recruitment-tracking-app-dev.appspot.com",
//     messagingSenderId: "578145829417",
//     appId: "1:578145829417:web:8eca648029ebc9364e1238",
//     measurementId: "G-M074N7NKBD"
// };
  
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_Id
};
  export default firebaseConfig;

  