import { Footer } from '../../components/footer';
// import { InnerHeader } from '../../components/header'
// import { JobApplyForm } from '../../components/container'
import { NavBar } from '../../components/navbar';

const ThankYou = (props) => {
    const { domain, tenant } = props
    return (
        <>
            {/* <InnerHeader /> */}
            <NavBar domain={domain} tenant={tenant}/>  
            <section className="container custheight">
                <div className="thankyoubox">
                    <h2 className="typo-h2">Thank you for applying!</h2>

                    <h6 className="typo-h6">Your application has been submitted.
                        <br />We will shortly get in touch with you!
                    </h6>
                </div>
            </section>
            <Footer domain={domain} tenant={tenant}/>


        </>
    )
}

export default ThankYou

