import { CircleLoader, ClipLoader, DotLoader, FadeLoader, SyncLoader } from "react-spinners";
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
//import { doc, setDoc } from "firebase/firestore";
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, limit, query, where } from 'firebase/firestore';
import { setCurrentPage, setJob } from '../../redux/actions/job.action';

import { Link } from "react-router-dom"
import { MyContext } from "../../pages/jobs/list"
import Pagination from "../../utils/pagination"
import _ from 'lodash';
import { connect } from 'react-redux'
import { db } from '../../firebase';

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};



const HomeContainer = (props) => {
  const { domain } = props
  //console.log('domain are:: ',domain)
  
  const { setJob, isTenant, setCurrentPage, currentPageNumber } = props

  const { tenant, jobs, value, incrementValue, onChangeValue, jobSearch, searchValueFlag, setSearchValueFlag } = useContext(MyContext);

  console.log('tenant are:::',tenant)
  console.log('jobs are:::',jobs)
  console.log('isTenant are:: ',isTenant)

  const [activePage, setActivePage] = useState(currentPageNumber);
  const [searchValue, setSearchValue] = useState("")
  const [searchJob, setSearchJob] = useState(0)
 


  const [dataMapFlag, setDataMapFlag] = useState(true)


  //const [getJobFlag, setGetJobFlag] = useState(true)

  const [filterFlag, setFilterFlag] = useState(true)
  const [jobType, setJobType] = useState([])

  const [loading, setLoading] = useState(false)
  const [loadingFlag, setLoadingFlag] = useState(true)
  let [color, setColor] = useState("#52BD94");
  const [isOpen, setIsOpen] = useState(false)


  const pageSize = 6;

  useEffect(() => {
    setLoading(true)
    setLoadingFlag(true)
    setJob({})
  }, []);

  const getJobType = async (tenant) => {

    const colRef = collection(db, `HiremeApp/${tenant.id}/JobType`);
    const jobTypeQuery = query(colRef);
    const docSnap = await getDocs(jobTypeQuery);


    let result = [];


    docSnap.docs.forEach(item => {

      result.push({ ...item.data(), id: item.id });

    })
    let arr = []
    result[0].Types.forEach(type => {
      arr.push({ key: type, value: type, checked: false, selected: false })

    })
    setJobType(arr)

  }

  

  if (Object.keys(jobSearch).length > 0 && dataMapFlag) {
    setActivePage(1)
    setDataMapFlag()
  }



  const filterBy = (term) => {
    const escapeRegExp = (str) => str.replace('[-A-Z0-9+&@#/%?=~_|!:,.;]');

    const re = new RegExp(escapeRegExp(term), 'i');
    return (person) => {
      for (let prop in person) {
        if (!person.hasOwnProperty(prop)) {
          continue;
        }
        if (prop === 'job_title') {
          if (re.test(person[prop])) {
            return true;
          }
        }
      }
      return false;
    };
  };


  const JobList = useMemo(() => {
    const firstPageIndex = (currentPageNumber - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    if (searchValue !== "" && searchJob) {
      jobs = jobs.filter(filterBy(searchValue));
      return jobs.slice(firstPageIndex, lastPageIndex);
    } else {
      return jobs.slice(firstPageIndex, lastPageIndex);
    }
  }, [activePage, searchValue, searchJob, searchValueFlag, jobSearch, jobs, currentPageNumber]);
  
  console.log('JobList are:::',JobList)
  const handleChangeFilter = (e, jobvalue) => {
    let jobArr = jobType
    if (e.target.checked) {
      jobArr.forEach((item, idx) => {
        if (jobvalue === item.value) {
          item.selected = true
          item.checked = true
        } else {
          item.selected = false
          item.checked = false
        }
      })
      setJobType(jobArr)

      let searchJobFilter = JSON.parse(JSON.stringify(jobSearch))
      searchJobFilter['job_type'] = jobvalue
      onChangeValue(searchJobFilter)
    } else {
      jobArr.forEach((item, idx) => {
        if (item.value === jobvalue) {
          item.selected = false
          item.checked = false
        }
      })

      setJobType(jobArr)

      let searchJobFilter1 = JSON.parse(JSON.stringify(jobSearch))
      //console.log('searchJobFilter1 aree:: ',searchJobFilter1)
      delete searchJobFilter1['job_type']
      onChangeValue(searchJobFilter1)
    }
    setCurrentPage(1)
  }

  const clearFilter = (e) => {
    e.preventDefault()
    setSearchValueFlag(true)
    let jobArr = jobType
    jobArr.forEach((item, idx) => {
      item.selected = false
      item.checked = false
    })
    setJobType(jobArr)
    let searchJobFilter1 = JSON.parse(JSON.stringify(jobSearch))
    delete searchJobFilter1['job_type']
    onChangeValue(searchJobFilter1)
    setCurrentPage(1)
  }

  if (tenant && tenant.length > 0 && filterFlag) {
    getJobType(tenant[0])
    setFilterFlag(false)
  }
  
  // if (JobList && JobList.length == 0 && loading) {
  //   console.log('fffgfgggggg')
  //   setLoading(false)
  // }

  // if (JobList && JobList.length > 0 && loadingFlag) {
  //   console.log('aaaaaaa')
  //   setLoadingFlag(false)
  // }
  // if(tenant && tenant.length === 0 && jobs && jobs.length === 0 && JobList && JobList.length === 0 && !isTenant && loadingFlag){
  //   console.log('dddddddd')
  //   setLoadingFlag(false)
  //   setLoading(false)
  // }

  if(JobList && JobList.length === 0  && !isTenant && loadingFlag){
    setLoadingFlag(false)
    setLoading(false)
  }
  if(JobList && JobList.length > 0 && isTenant && loading){
    setLoading(false)
  }
  const openFilter = () => {
    if (!isOpen) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }

  const closeFilterPopUp = () => {
    setIsOpen(false)
  }

  const handleChangeActivePage = (pageNumber) => { 
    setCurrentPage(pageNumber)
  }
  return (
    <>
      {loadingFlag || loading ?
        <FadeLoader
          color={color}
          loading={loading}
          cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        : null}
      <section className="current-openings">
        <div className="container">
          <div className="row">
            <span className="icon filterIcon" onClick={openFilter}>
              <i className="fa fa-filter" />
            </span>
            <div className={`left-sec ${isOpen ? "left-secFilter" : ""}`}>

              <div className="filterheading" style={{ zIndex: '9' }}>
                <div className="closefilter" onClick={closeFilterPopUp}>X</div>
                <h5 className="typo-h5">Filters</h5>
                <p className="typo-p-14" onClick={clearFilter}>Clear filters</p>
              </div>
              <div className="filterbody">
                <h6 className="typo-h6">Job Type</h6>
                <div className="filterbody-cont">
                  {jobType && jobType.length > 0 && jobType ?
                    jobType.map((item, idx) => {
                      return (

                        <div key={idx}>
                          <label className="chekmark-style" >{item.value}
                            <input type="checkbox"
                              checked={item.selected}
                              value={item.checked}
                              onChange={(e, data) => handleChangeFilter(e, item.value)} />
                            <span className="checkmark" />
                          </label>
                        </div>

                      )
                    })
                    : <></>
                  }
                </div>
              </div>
            </div>
            <div className="right-sec">
              <h4 className="typo-h4">Current Job Openings</h4>
              <div className="">
                <p className="typo-p-14">{jobs && jobs.length && jobs.length > 0 ? jobs.length : 'No'} Current Openings found</p>

                {JobList && JobList.length && JobList.length > 0 ? JobList.map((job, idx) => {
                  const jobDescription = job.job_description;
                  const length = 300;
                  const shortJobDescription = jobDescription.substring(0, length);
                  return (
                    <div key={idx}>
                      <div className="card" >
                        <div className="cardtop">
                          <Link to={`/${domain}/job/${job.id}`}>

                            <h3 className="typo-h3 wt-card-title">{job.job_title}</h3>
                            <small>Job ID:  {job.job_id} </small>
                          </Link>
                          <Link to={`/${domain}/job/${job.id}`} className="button button-secondary applynow-web">Apply Now</Link>
                        </div>
                        <div className="cardbages">
                          <p className="tags">{job.job_type}</p>
                          <p className="tags"><img src="assets/images/map-pin.png" />{job.location ? job.location : 'NA'}</p>
                          <p className="tags"><img src="assets/images/briefcase.png" />Exp: {job.experience_needed}</p>
                          <p className="tags"><img src="assets/images/clock.png" />{job.job_opening_date}</p>
                        </div>
                        <div className="cardinfo">
                        <div
                            dangerouslySetInnerHTML={{
                              __html: job && job.job_description ? shortJobDescription : null,
                            }}
                          />
                          {jobDescription ? <Link className="readmore" to={`/${domain}/job/${job.id}`}>[ Read More.. ]</Link> : null}
                          
                        </div>
                        <div className="applynow-mobile">
                          <Link to={`/${domain}/job/${job.id}/jobApply`} className="button button-secondary wt-button-secondary">Apply Now</Link>
                        </div>
                      </div>
                    </div>
                  )
                }) : <></>}


                <Pagination
                  className='pagination-bar'
                  currentPage={currentPageNumber}
                  totalCount={
                    jobs && jobs.length
                  }
                  pageSize={pageSize}
                  onPageChange={(page) => handleChangeActivePage(page)}
                //onChange = {handlePagintaion}
                />

                <div className="bottom-card">
                  <div className="bottomcardtop">
                    <div className="bottomcardinner">
                      <h3 className="typo-h4">Can't find what you're looking for?</h3>
                      <p className="typo-p-14">Upload your resume and we'll match you with relevant
                        opportunities at Segment.
                      </p>
                    </div>
                    <div>
                      <div className="button button-whitebg"><Link to={`/${domain}/jobApply`}>Upload your resume</Link></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
      </section>
    </>
  )
}


const mapStateToProps = (state) => ({
  loading: state.jobReducer.loading,
  jobData: state.jobReducer.job,
  currentPageNumber: state.jobReducer.currentPageNumber
})

const mapDispatchToProps = {
  setJob,
  setCurrentPage
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer)
