import { CURRENT_PAGE_JOB_REQUEST, GET_JOBLIST_REQUEST, GET_JOB_REQUEST, ONSEARCH_JOB_REQUEST, SET_JOB_REQUEST } from '../constants/job.constant'

export const getJob = (data) => {
    return{
        type: GET_JOB_REQUEST,
        data
    }
}

export const setJob = (data) => {
    return{
        type: SET_JOB_REQUEST,
        data
    }
}

export const onSearchFlag = (data) => {
    return{
        type: ONSEARCH_JOB_REQUEST
    }
}

export const setCurrentPage = (pageNumber) => {
    return{
        type: CURRENT_PAGE_JOB_REQUEST,
        pageNumber
    }
}

export const getJobList = (data) => {
   // console.log("data400",data)
    return{
        type:GET_JOBLIST_REQUEST,
        data
    }
}