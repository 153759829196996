import React from 'react';
// import {MyContext} from "../../pages/jobs/list"
import { SearchComponent } from '../search' ;

const MainHeader = () => {
  // const { value, incrementValue } = useContext(MyContext);
  return (
    <>

      <div id="header" className="banner">
          <div className="container">
            <div className="hero-text">
              <div>
                <h1 className="typo-h2">Start your career at <span className="different">Segment</span> and let’s <br />
                  unleash your full potential</h1>
              </div>
              <div>
                <p className="typo-h6">Join a team that celebrates each other!</p>
                <p className="typo-h6">Hi Friend! We are super excited that you are taking a step forward for joining
                  us. We
                  are looking for<br />
                  motivated, curious and creative people who are superexcited in building somthing new,
                  innovative.
                </p>
                <p className="typo-h6">Let's create something spectacular together!</p>
              </div>
              <div>
               {/* <input type="button" value="Click" onClick={incrementValue} /> */}
               {/* {value} */}
                <SearchComponent />
                
              </div>
            </div>
          </div>
        </div>
        </>
    )
}

export { MainHeader }

