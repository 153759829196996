// import { Link } from "react-router-dom"

const HomePage = () => {
    return (
        <>
            <div className="home-section">
                <p>Welcome to Career Portal</p>
                <p>Please enter Job opening URL to proceed further</p>
            </div>
        </>
    )
}

export default HomePage

