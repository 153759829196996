import { CURRENT_PAGE_JOB_REQUEST, GET_JOBLIST_REQUEST, GET_JOB_ERROR, GET_JOB_REQUEST, GET_JOB_SUCCESS, SET_JOB_ERROR, SET_JOB_REQUEST, SET_JOB_SUCCESS } from '../constants/job.constant'

const initialState = {
  job: {},
  jobList:[],
  currentPageNumber: 1,
  loading: false
}

const job = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB_REQUEST:
      return{
        ...state,
       job: action.data,
       loading: true
      }
    case GET_JOB_SUCCESS:
        return{
            ...state,
            loading: false
        }
    case GET_JOB_ERROR:
      return{
        ...state,
        loading: false
      }
    case SET_JOB_REQUEST:
      return{
        ...state,
        job: action.data,
      }
    case CURRENT_PAGE_JOB_REQUEST:
      return{
        ...state,
        currentPageNumber: action.pageNumber,
        }

      case GET_JOBLIST_REQUEST:
        return{
          ...state,
          jobList:action.data
        }
    default:
      return state
  }
} 

export default job;