import { Link } from "react-router-dom"
import logo from '../../assets/images/logo.png';
const Footer = (props) => {
  const { tenant, domain } = props;
  let companyLogo = ""
    if(tenant && tenant.length > 0){
        companyLogo = tenant[0].companyLogo;
    }
    return (
      <>
        {/* <footer className="footersec">
          <div className="container">
            <div className="footerlogo-left">
              <img src={logo} />
            </div>
            <div className="middlesec">
              <p className="typo-p-14" style={{marginRight: '5px'}}>About Company </p> |
              <p className="typo-p-14" style={{marginLeft: '5px'}}> Write to us</p>
            </div>
            <div className="footerlogo-right">
              <p className="typo-p-14">Powered By</p>
              <img src="/assets/images/hireme.png" />
            </div>
          </div>
        </footer> */}
        <footer className="footersec">
        <div className="container">
          <div className="footerlogo-left">
          <Link to={`/${domain}`}><img src={companyLogo} /></Link>
          </div>
          <div className="middlesec">
            <p className="typo-p-14">About Company </p> |
            <p className="typo-p-14"> Write to us</p>
          </div>
          <div className="footerlogo-right">
            <p className="typo-p-14">Powered By</p>
            <img src="/assets/images/hireme.png" />
          </div>
        </div>
      </footer>
    </>
    )
}

export { Footer }

