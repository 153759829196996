import React, { useEffect, useState } from 'react';
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, limit, onSnapshot, query, where } from 'firebase/firestore';

import { Footer } from '../../components/footer';
import { InnerHeader } from '../../components/header'
import { InnerJobDetailContainer } from '../../components/container'
import { NavBar } from '../../components/navbar';
import { connect } from 'react-redux'
import { db } from '../../firebase';
import { getJob } from '../../redux/actions/job.action';
import { useLocation } from "react-router-dom";

const JobDetails = (props) => {

  const { getJob, jobData, domain } = props
  const [tenant, setTenant] = useState([])
  useEffect(() => {
    getTenant(domain)
  }, [])

  const getTenant = async (domain) => {
    const colRef = collection(db, "HiremeTenants");
    const todosQuery = query(colRef, where('portalDomain', '==', domain));
    const docSnap = await getDocs(todosQuery);

    let result = [];
    docSnap.docs.forEach(item => {
      //console.log('itemmm:: ',item.id)
      result.push({ ...item.data(), id: item.id });

    })
    setTenant(result)
  }

  // console.log('props are:: ',[props,window.location]);//12345
  // console.log('domain in detial page:; ',domain)
  // console.log('tenant are in details pagee::: ',tenant)
  let searchUrl = window.location.pathname
  let uid = searchUrl.split("job/")
  //console.log('uid are::',uid)
  const [getJobFlag, setGetJobFlag] = useState(true)
  const [jobs, setJobs] = useState([])
  const [jobStoreFlag, setJobStoreFlag] = useState(true)

  const fetchJob = async (tenant, uid) => {
    // console.log('uid are:: ',uid)
    // console.log('tenant are:: ',tenant)
    //const colRef = collection(db, `HiremeApp/${tenantId}/JobOpenings`);
    const docRef = doc(db, `HiremeApp/${tenant.id}/JobOpenings`, uid);
    const todosQuery = query(docRef);
    const docSnap = await getDoc(todosQuery);


    let result = [];

    if (docSnap.exists()) {
      setJobs({ ...docSnap.data(), id: docSnap.id });
    }

    // onSnapshot(doc(db, "HiremeApp/WebutersTechnologies-xwvy0/JobOpenings", uid), (doc) => {
    //     console.log("Current data: ", doc.data());
    //     setJobs({ ...docSnap.data(), id: docSnap.id });
    // });

    //setJobs(result)
  }


  //console.log('jobs are in details pageeeeee@@@:: ',jobs)
  if (uid && uid.length > 0 && tenant && tenant.length > 0 && getJobFlag) {
    //console.log('uid exist:: ',uid[1])
    fetchJob(tenant[0], uid[1]);
    setGetJobFlag(false)
  }
  return (
    <>
      <div>
        <NavBar domain={domain} tenant={tenant}/>          
        <InnerHeader tenant={tenant} domain={domain}/>

        <InnerJobDetailContainer job={jobs} domain={domain} />
        <Footer domain={domain} tenant={tenant}/>
      </div>
    </>
  )
}

//export default JobDetails

const mapStateToProps = (state) => ({
  loading: state.jobReducer.loading,
  jobData: state.jobReducer.job
})

const mapDispatchToProps = {
  getJob,
}

export default connect(mapStateToProps, mapDispatchToProps)(JobDetails)