import { useContext, useState } from "react"

import { MyContext } from "../../pages/jobs/list"
import { connect } from 'react-redux'
import { setCurrentPage } from '../../redux/actions/job.action';

const SearchComponent = (props) => {
    const { setCurrentPage } = props
    const { value, jobSearch, onChangeValue, incrementValue, setSearchValueFlag } = useContext(MyContext);

    const [searchJob, setSearchJob] = useState("")
    const [searchLocation, setSearchLocation] = useState("")

    const handleChangeSearch = (e) => {
      if(e.target.name === "job_search"){
        setSearchJob(e.target.value)
        setSearchValueFlag(true)
      }

      if(e.target.name === "location_search"){
        setSearchLocation(e.target.value)
        setSearchValueFlag(true)
      }
     
      if(e.target.value == ""){
        console.log('empty haiaiii')
       
        setSearchValueFlag(true)
        if(e.target.name === "job_search"){
          setSearchJob("")
          
          onChangeValue({title:e.target.value, location: searchLocation,job_type:jobSearch && jobSearch.job_type ? jobSearch.job_type:""})
        }
         if(e.target.name === "location_search"){
          onChangeValue({title:searchJob, location: e.target.value,job_type:jobSearch && jobSearch.job_type ? jobSearch.job_type:""})
          setSearchLocation("")
         }
       
        setCurrentPage(1)
      }
      
    }

    const handleSearchSubmit = () => {
      setCurrentPage(1)
       onChangeValue({title:searchJob, location: searchLocation,job_type:jobSearch && jobSearch.job_type ? jobSearch.job_type:""})
    }

    return (
        <>
              <div className="search-sec">
                <h4 className="typo-h4">Lets find your dream job</h4>
                <div className="downsec">

                  <div className="search">
                    <div className="search-keyword">
                    <img src="assets/images/search.png" />
                    {/* <input className="input-elevated" type="text" placeholder="Search job title or keyword" />  */}
                    <input
                        type="text"
                        onChange={handleChangeSearch}
                        className="input-elevated"
                        placeholder="Search Job by title …"
                        defaultValue={searchJob}
                        name="job_search"
                    />
                    </div>
                    <p className="sideline"></p>
                    <div className="search-location">
                    <img className="loc" src="assets/images/locaion.png" />
                    <input
                        type="text"
                        onChange={handleChangeSearch}
                        className="input-elevated"
                        placeholder="Search Job by location …"
                        defaultValue={searchLocation}
                        name="location_search"
                    />
                    </div>
                    
                  </div>
                 
                  
                    
                    <input className="button button-secondary desktopbtn" type="button" onClick={() => handleSearchSubmit()} value="Search Jobs" />
                  
                </div>
                <div className="searchjob-mobile">
                  {/* <div className="button button-secondary mobilebtn">Search Jobssssss</div> */}
                </div>
              </div>
        </>
    )
}


const mapStateToProps = (state) => ({
  loading: state.jobReducer.loading,
  jobData: state.jobReducer.job,
  currentPageNumber: state.jobReducer.currentPageNumber
})

const mapDispatchToProps = {
  setCurrentPage
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchComponent)