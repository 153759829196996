import React,{useEffect, useState} from 'react';
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, limit, onSnapshot, query, where } from 'firebase/firestore';

import { Footer } from '../../components/footer';
import { InnerHeader } from '../../components/header'
import { JobApplyForm } from '../../components/container'
import { NavBar } from '../../components/navbar';
import { connect } from 'react-redux'
import { db } from '../../firebase';
import { getJob } from '../../redux/actions/job.action';

const JobApply = (props) => {
    const { domain } = props
    //console.log('domain in job apply page:; ',domain)
    
    const [tenant,setTenant]=useState([])
    useEffect(() => {
      getTenant(domain)
    }, [])

    const getTenant = async (domain)=>{
        const colRef = collection(db, "HiremeTenants");
        const todosQuery = query(colRef, where('portalDomain', '==', domain));
        const docSnap = await getDocs(todosQuery);

        let result = [];
        docSnap.docs.forEach(item=>{
            //console.log('itemmm:: ',item.id)
            result.push({ ...item.data(), id: item.id });
            
        })
        setTenant(result)
    }

    return (
        <>
           <div>
           <NavBar domain={domain} tenant={tenant}/>   
            <InnerHeader tenant={tenant}/>
            <JobApplyForm  domain={domain}/>
            <Footer domain={domain} tenant={tenant}/>
          </div>

            
            
        </>
    )
}

//export default JobApply

const mapStateToProps = (state) => ({
    loading: state.jobReducer.loading,
    //jobData: state.jobReducer.job
})

const mapDispatchToProps = {
    //getJob,
}

export default connect(mapStateToProps, mapDispatchToProps)(JobApply)