//import './App.css';
import './assets/css/fontawesome.min.css'
import './assets/scss/main.css'

import { addDoc, collection, doc, getDoc, getDocs, getFirestore, limit, query, where } from 'firebase/firestore';
import { createContext, useContext, useEffect, useState } from "react"

import { AppRouter } from "./routes"
import { BrowserRouter } from "react-router-dom"
import { db } from './firebase';
import { getApp } from "./utils/helpers"

export const HostContext = createContext();
const  App =  () => {
  let { pathname } =  getApp();
  console.log('hostname are:; ', pathname)
  let domainArr = pathname.split("/");
  console.log('domainArr are:: ',domainArr)

  return (
    <>
         <BrowserRouter>
            <HostContext.Provider value={domainArr[1]}>
              <AppRouter domain={domainArr[1]}/> 
            </HostContext.Provider>
         </BrowserRouter>
        
     </>
  );
}

export default App;
//export { HostContext }
