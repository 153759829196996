import { Link } from "react-router-dom"
import logo from '../../assets/images/logo.png';
// import { useState } from "react"

const NavBar = (props) => {
    const { tenant, domain } = props;
    //console.log('tenant in navbar:: ',tenant)
    let companyLogo = ""
    if (tenant && tenant.length > 0) {
        companyLogo = tenant[0].companyLogo;
    }

    //console.log('companyLogo are:: ',companyLogo)
    return (
        <nav className="navbar">
            <div className="navbar-container container">
                <div className="logo">

                    <Link to={`/${domain}`}><img src={companyLogo} /></Link>
                    {/* <h1 className="typo-h4">{tenant && tenant.length > 0 ? tenant[0].displayName : ""}</h1> */}
                    <h1 className="typo-h4">Career Portal</h1>
                </div>
                <div>
                    <div className="button button-primary">About company </div>
                </div>
            </div>
        </nav>
    )
}

export { NavBar }

